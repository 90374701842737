import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import StaticPageContent from '../components/StaticPageContent';

export const StaticPageTemplate = ({ content, title, helmet }) => (
  <section className="section">
    {helmet || ''}
    <div className="container content">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
            {title}
          </h1>
          {Array.isArray(content) ? (
            <StaticPageContent content={content} />
          ) : (
            <HTMLContent content={content} />
          )}
        </div>
      </div>
    </div>
  </section>
);

StaticPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const StaticPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { description: siteDescription },
    },
    markdownRemark: {
      frontmatter: { title, description, content },
      html,
    },
  } = data;
  const firstImage = ((content || [{}]).find(({ image }) => image) || {}).image;

  return (
    <Layout>
      <StaticPageTemplate
        content={html || content}
        helmet={
          <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description || siteDescription} />
            {firstImage && (
              <meta
                property="og:image"
                content={firstImage.childImageSharp.fluid.src}
              />
            )}
          </Helmet>
        }
        title={title}
      />
    </Layout>
  );
};

StaticPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default StaticPage;

export const pageQuery = graphql`
  query StaticPageByID($id: String!) {
    site {
      siteMetadata {
        description
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        content {
          title
          section
          image {
            childImageSharp {
              fluid(maxHeight: 600, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alignement
        }
      }
    }
  }
`;
