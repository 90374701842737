import React from 'react'
import classnames from 'classnames';
import capitalize from 'capitalize';
import { HTMLContent } from '../Content';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import styles from './styles.module.scss';

export const StaticPageContent = ({ content }) => content.map(({ title, section, image, alignement = 'center' }, index) => (
  <section
    key={index}
    className={styles.section}
  >
    {title && <h2 className={styles.section__title}>{title}</h2>}
    <div className={classnames(styles.section__content, styles[`section__content${capitalize(alignement||'')}`])}>
      {image && (
        <div className={styles.section__image}>
          <PreviewCompatibleImage imageInfo={{image}} />
        </div>
      )}
      {section && (
        <HTMLContent
          content={section}
          className={styles.section__text}
        />
      )}
    </div>
  </section>
));

export default StaticPageContent;
